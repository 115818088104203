import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  Col,
  Collapse,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  getTree,
  updateNomenclature,
} from "services/api";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";

import "./treeModal.scss";
import classNames from "classnames";

export const TreeModal = ({
  openModal,
  setOpenModal,
  id,
  getAndSetNomenclatureOne,
}) => {
  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState([]);
  const [filteredTree, setFilteredTree] = useState([]);
  const [collapse, setCollapse] = useState([]);
  const [productGroup, setProductGroup] = useState(null);

  const getAndSetTree = () => {

    getTree()
      .then((res) => {
        setTree(res);
        setFilteredTree(res);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!openModal) return;
    setLoading(true);
    getAndSetTree();
  }, [openModal]);

  const editHandler = (productGroup) => {
    setLoading(true);
    updateNomenclature({
      id,
      productGroup
    })
      .then((res) => {
        toast.success("Товарная группа успешно сохранена");
        if (getAndSetNomenclatureOne) {
          getAndSetNomenclatureOne();
        }
        setOpenModal(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const collapseHandler = (item) => {
    setCollapse((prev) => {
      if (collapse.find(e => e === item.id)) {
        prev.splice(
          prev.findIndex((val) => val === item.id),
          1
        );
        return [...prev];
      }
      return [...prev, item.id];
    })

  }

  const mapper = (tree) => {
    return tree.map((item, i) => <>
      <ListGroupItem
        key={item.id}
        onClick={() => {
          if (item.children.length > 0) {
            collapseHandler(item);
          } else {
            editHandler(item.id);
          }
        }}
        style={{ paddingLeft: `${25 * item.level}px` }}
        className={classNames({ 'border-bottom-0': item.children.length > 0 || (item.level > 1 && i + 1 === tree.length) })}
      >
        <Button
          className="pl-0"
          color="orange"
          id={item.id}>
          {item.children.length > 0 &&
            <>{collapse.find(e => e === item.id) ? '-' : '+'}</>
          }
        </Button>

        {item.name}


      </ListGroupItem >
      <>{item.children.length > 0 &&
        <Collapse isOpen={collapse.find(e => e === item.id)}>
          {mapper(item.children)}
        </Collapse>
      }</>
    </>
    )
  }

  const searchAndPreserveStructure = (array, searchTerm) => {
    const results = [];

    for (const item of array) {
      const resultItem = { ...item };
      const foundChildren = searchAndPreserveStructure(item.children, searchTerm);

      if (item.name.includes(searchTerm) || foundChildren.length > 0) {
        resultItem.children = foundChildren;
        results.push(resultItem);
      }
    }

    return results;
  }

  const search = (search) => {
    setFilteredTree(searchAndPreserveStructure(tree, search));
  }

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="tree-modal"
    >
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">
          Категории
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row className="main-info">
          <Col>
            <div className="d-flex">
              <Button className="mr-2 mb-2 flex-1" onClick={() => setCollapse([])}>{collapse.length === 0 ? `Развернуть` : `Свернуть`}</Button>
              <Input onChange={e => search(e.target.value)} />
            </div>
            <ListGroup className="tree-list-group">
              {mapper(filteredTree)}
            </ListGroup>
          </Col>
        </Row>
      </ModalBody>
    </Modal >
  );
};
