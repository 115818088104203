import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat } from "utils/format";
import { useHistory } from "react-router-dom";
import { Cut } from "components/Cut";

import "./sendingPriceListTable.scss";

export const SendingPriceListTable = ({ sendingPriceLists }) => {

  console.log(sendingPriceLists);


  const history = useHistory();
  const tableColumns = [
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "lastDateHistory",
      text: "Дата",
      formatter: (cell, row) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "customerName",
      text: "Контрагент",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => <Cut>{row.specification?.emails?.join(', ')}</Cut>,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "priceList",
      text: "Прайс листы",
      formatter: (cell, row) => <Cut width>{row.specification?.price_list?.map((priceList) => priceList.name).join(', ')}</Cut>,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "warehouses",
      text: "Склады",
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <Cut>{row.specification?.warehouses?.join()}</Cut>,
      headerClasses: () => "right",
      classes: () => "right",
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/sales/sendingPriceList/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={sendingPriceLists}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
