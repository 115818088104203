import React, { useEffect, useState } from "react";
import Select from "react-select";

import { updateNomenclature } from "services/api";

export const ChangeStatus = ({ row, statuses, setLoading, getAndSetNomenclature }) => {
  const [status, setStatus] = useState({
    label: row.status,
    value: statuses.find(v => v.label === row.status),
  });

  const warehouseHandler = (data) => {
    setStatus(data);
    setLoading(true);
    updateNomenclature({
      id: row.id,
      status: data.value
    })
      .then((res) => getAndSetNomenclature());
  };

  return (
    <Select
      name="status"
      placeholder="Выберите статус"
      className="react-select-container"
      classNamePrefix="react-select"
      options={statuses}
      value={status}
      onChange={warehouseHandler}
    />
  );
};
