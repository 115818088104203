import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Row,
  Alert
} from "reactstrap";

import {
  carryOutCorrOrder,
  createCorrOrder,
  downloadCorrOrder,
  getCorrOrderOne,
  getOrderForCorr,
  sendCorrOrderToDiadoc,
  updateCorrOrder,
} from "services/apiSupply";
import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { MySpinner } from "components/MySpinner";
import { CorrOrderProductTable } from "features/CorrOrders/CorrOrderProductTable";
import { CorrOrderProductTableNew } from "features/CorrOrders/CorrOrderProductTableNew";

import "./corrOrderOne.scss";

const REASONS = [
  { value: 1, label: "Отказ клиента" },
  { value: 2, label: "Брак" },
  { value: 3, label: "Количественная разница" },
  { value: 4, label: "Пересорт" },
];

export const CorrOrderOnePage = () => {
  const history = useHistory();
  const { id } = useParams();

  const [isNew, setIsNew] = useState(!id ? true : false);
  const [number, setNumber] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [order, setOrder] = useState(null);
  const [reason, setReason] = useState(null);
  const [products, setProducts] = useState([]);
  const [isCorrect, setIsCorrect] = useState(false);
  const [carriedOut, setCarriedOut] = useState(false);
  const [corrNumber, setCorrNumber] = useState("");
  const [sentToDiadoc, setSentToDiadoc] = useState(false);
  const [error, setError] = useState("");
  const [comment, setComment] = useState("");

  const getAndSetOrder = () => {
    if (!orderNumber) return;
    setLoading(true);
    getOrderForCorr(orderNumber)
      .then((res) => {
        setOrder(res);
        setProducts(
          res.products.map((val) => {
            return { ...val, countBefore: val.count, countAfter: val.count };
          })
        );
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetCorrOrder = () => {
    getCorrOrderOne(id)
      .then((res) => {
        setProducts(res.products);
        setOrder(res.order);
        setNumber(res.number);
        setDate(res.date);
        setCarriedOut(res.carriedOut);
        setCorrNumber(res.corrNumber);
        setReason(REASONS.find(val => val.value === res.reason));
        setSentToDiadoc(res.sentToDiadoc);
        setComment(res.comment);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const createCorrOrderHandler = () => {
    if (!reason?.value) {
      toast.error("Необходимо выбрать причину возврата.");
      return;
    }
    setLoading(true);
    createCorrOrder({
      order: {
        id: order.id,
      },
      products,
      reason: reason?.value,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Корректировка успешно создана");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const updateCorrOrderHandler = (reason) => {
    if (!id) {
      return;
    }
    setLoading(true);
    updateCorrOrder({
      id,
      reason: reason?.value,
      comment,
    })
      .then((res) => {
        setReason(REASONS.find(val => val.value === res.reason));
        toast.success("Корректировка успешно обновлена");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutCorrOrder({ id, carryOut: carriedOut ? 0 : 1 })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        if (res.carriedOut) {
          toast.success("Корректировка успешно проведена");
        } else {
          toast.success("Корректировка успешно распроведена");
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const diadocHandler = () => {
    setLoading(true);

    sendCorrOrderToDiadoc({ id })
      .then((res) => {
        toast.success("Успешно отправлено в Диадок");
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          setError(e.response?.data?.message);
        } else {
          setError("Ошибка при отправке в диадок");
        }
      })
      .finally(() => setLoading(false));

  }

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetCorrOrder();
  }, []);

  const downloadHandler = () => {
    if (!carriedOut) {
      toast.error("Для того чтобы скачать, нужно провести корректировку");
      return;
    }

    downloadCorrOrder({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Корректировка реализации № ${corrNumber} от ${dateFormat(
            order.date
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новая корректировка реализации`;
    }

    return `Корректировка реализации № ${corrNumber}`;
  };

  return (
    <Container fluid className="p-0 corr-order-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        {!isNew && (
          <div className="corr-order-one__carried-out-button">
            {carriedOut && <CheckCircle color="green" />}

            <Button
              color="primary"
              onClick={carryOutHandler}
              className="button-wide ml-1"
            >
              {carriedOut ? `Распровести` : `Провести`}
            </Button>
          </div>
        )}
      </HeaderPage>

      {error && <Alert color="danger">{error}</Alert>}

      <Card className="corr-order-one__form mb-0">
        <CardBody className="pb-3">
          {isNew ? (
            <>
              <Row>
                <Col md="6">
                  <Label className="text-sm-right">Заказ</Label>
                  <InputGroup>
                    <Input
                      name="orderNumber"
                      placeholder="Заказ"
                      value={orderNumber || ""}
                      onChange={(e) => setOrderNumber(e.target.value)}
                    />
                    <Button color="primary" onClick={getAndSetOrder}>
                      загрузить
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">Клиент</Col>
                <Col md="9">
                  <b>{order?.orgNameShort}</b>
                </Col>
              </Row>
              <Row>
                <Col md="3">Дата реализации</Col>
                <Col md="9">
                  <b>{dateFormat(order?.shippingDate)}</b>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">
                    Номер первой корректировки:
                  </Label>
                </Col>
                <Col md="9">
                  <b>{number}</b>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">
                    Дата первой корректировки:
                  </Label>
                </Col>
                <Col md="9">
                  <b>{dateFormat(date)}</b>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">Заказ: </Label>
                </Col>
                <Col md="9">
                  <b>
                    <Link to={`/sales/orders/${order?.id}`}>
                      № {order?.number}
                    </Link>
                  </b>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">Клиент: </Label>
                </Col>
                <Col md="9">
                  <b>{order?.customer}</b>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col md="3">
              <Label className="text-sm-right">Причина возврата: </Label>
            </Col>
            <Col md="9">
              <Select
                name="reason"
                placeholder="Причина возврата"
                className="react-select-container"
                classNamePrefix="react-select"
                options={REASONS}
                value={reason}
                onChange={(val) => {
                  setReason(val);
                  updateCorrOrderHandler(val);
                }}
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Комментарий:</Label>
            </Col>
            <Col md="9">
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </Col>
          </Row>

          {!isNew && (
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={updateCorrOrderHandler}
                  className="button-wide"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          )}

          {!isNew && (
            <>
              <Button
                color="primary"
                onClick={downloadHandler}
                className="button-wide mt-4"
              >
                Скачать
              </Button>
              <>{carriedOut && <Button
                color="primary"
                onClick={diadocHandler}
                className="button-wide mt-4 ml-3"
              >
                {sentToDiadoc && (
                  <CheckCircle color="green" className="sent-diadoc" />
                )}
                Выгрузить в Диадок
              </Button>}</>
            </>
          )}
        </CardBody>
      </Card>


      {isNew ? (
        <CorrOrderProductTableNew
          products={products}
          setIsCorrect={setIsCorrect}
        />
      ) : (
        <CorrOrderProductTable
          products={products}
          setIsCorrect={setIsCorrect}
        />
      )}

      {isCorrect && (
        <Card>
          <CardBody>
            {isNew && (
              <Button
                color="primary"
                onClick={createCorrOrderHandler}
                className="button-wide"
              >
                Создать
              </Button>
            )}
          </CardBody>
        </Card>
      )}
    </Container>
  );
};
