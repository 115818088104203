import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Trash, Edit } from "react-feather";
import classNames from "classnames";
import Select from "react-select";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";

import {
  createContract,
  createOrUpdateContract,
  getToWordAdditionalAgreement,
  getToWordContract,
  removeContract,
  setDefaultContract,
  updateContract,
  uploadAdditionalAgreement,
  uploadContract,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { dateFormat, dateFormatU } from "utils/format";
import { Checkbox } from "components/Checkbox";
import { TableButton } from "components/TableButton";
import { FormTitle } from "components/FormTitle";

import { useUser } from "../UserContext";

import "./contracts.scss";


const STATUSES = [
  { "label": "Возвращен", "value": 1 },
  { "label": "Не возвращен", "value": 2 },
  { "label": "Возвращен некорректный", "value": 3 }
];

export const Contracts = ({ customer }) => {
  const inputContractFile = useRef();
  const inputAdditionalAgreementFile = useRef();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const [contracts, setContracts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showFormAdditionalAgreement, setShowFormAdditionalAgreement] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState("");
  const [creditSumMax, setCreditSumMax] = useState("");
  const [delayDayCount, setDelayDayCount] = useState("");
  const [additionalAgreements, setAdditionalAgreements] = useState([]);
  const [numberAdditionalAgreement, setNumberAdditionalAgreement] = useState("");
  const [dateAdditionalAgreement, setDateAdditionalAgreement] = useState("");
  const [idAdditionalAgreement, setIdAdditionalAgreement] = useState("");
  const [contractId, setContractId] = useState(null);
  const [contractFile, setContractFile] = useState(null);
  const [additionalAgreementFile, setAdditionalAgreementFile] = useState(null);

  useEffect(() => {
    setContracts(customer.contracts);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const addContractHandler = () => {
    if (!date) {
      toast.error("Заполните дату договора");
      return;
    }
    setLoading(true);

    createOrUpdateContract({
      customer: customer.id,
      name,
      number,
      date,
      creditSumMax: parseInt(creditSumMax),
      delayDayCount: parseInt(delayDayCount),
      id: contractId
    })
      .then((res) => {
        setName("");
        setNumber("");
        setDate("");
        setCreditSumMax("");
        setDelayDayCount("");
        setContracts(res);
        toast.success(
          contractId ? "Договор успешно обновлен" : "Договор успешно добавлен"
        );
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при добавлении договора"
        );
      })
      .finally(() => {
        setShowForm(false);
        setLoading(false);
      });
  };

  const resetForm = () => {
    setName("");
    setNumber("");
    setDate("");
    setCreditSumMax("");
    setDelayDayCount("");
    setContractId(null);
    setShowForm(false);
  };

  const editContractShowForm = (id) => {
    setShowForm(true);
    const contract = contracts.find((contract) => contract.id === id);
    setContractId(contract.id);
    setName(contract.name);
    setNumber(contract.number);
    setDate(dateFormatU(contract.date));
    setCreditSumMax(contract.creditSumMax);
    setDelayDayCount(contract.delayDayCount);
    setAdditionalAgreements(contract.additionalAgreement);
  };

  const removeContractHandler = (id) => {
    setLoading(true);
    removeContract({ id })
      .then((res) => {
        setContracts(res);
        setShowFormAdditionalAgreement(false);
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => setLoading(false));
  };

  const defaultContractHandler = (val, id) => {
    if (!val) {
      return;
    }
    setLoading(true);
    setDefaultContract({ id })
      .then((res) => {
        setContracts(res);
        toast.success("Данные успешно обновлены");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const statusHandler = (status, id) => {
    setLoading(true);
    updateContract({
      id,
      status
    })
      .then((res) => {
        setContracts(res);
        toast.success("Данные успешно обновлены");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const getContractHandler = (id) => {
    getToWordContract({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Договор поставки ООО ЛИДЕР ТРАК.docx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addAdditionalAgreement = () => {
    setLoading(true);

    const index = idAdditionalAgreement
      ? additionalAgreements.findIndex(item => item.id === idAdditionalAgreement)
      : null;

    if (index !== null) {
      additionalAgreements[index] = {
        id: idAdditionalAgreement,
        number: numberAdditionalAgreement,
        date: dateAdditionalAgreement,
        creditSumMax: parseInt(creditSumMax),
        delayDayCount: parseInt(delayDayCount)
      };
    }

    updateContract({
      id: contractId,
      additionalAgreements: idAdditionalAgreement
        ? additionalAgreements
        : [
          ...additionalAgreements,
          {
            number: numberAdditionalAgreement,
            date: dateAdditionalAgreement,
            creditSumMax: parseInt(creditSumMax),
            delayDayCount: parseInt(delayDayCount)
          }
        ]
    })
      .then((res) => {
        setContracts(res);
        const contract = res.find(v => v.id === contractId);
        if (contract) {
          setAdditionalAgreements(contract.additionalAgreement);
          setCreditSumMax(contract.creditSumMax);
          setDelayDayCount(contract.delayDayCount);
        }
        toast.success("Данные успешно обновлены");
        setNumberAdditionalAgreement("");
        setDateAdditionalAgreement("");
        setIdAdditionalAgreement("");
        setShowFormAdditionalAgreement(false);

      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAdditionalAgreementHandler = (item) => {
    getToWordAdditionalAgreement({ id: item.id, contractId })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Доп.соглашение к договору №${number} от ${dateFormat(item.date)} .docx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeAdditionalAgreementHandler = (id) => {
    setLoading(true);
    additionalAgreements.splice(
      additionalAgreements.findIndex((val) => val.id === id),
      1
    );
    updateContract({
      id: contractId,
      additionalAgreements
    })
      .then((res) => {
        setContracts(res);
        const contract = res.find(v => v.id === contractId);
        if (contract) {
          setAdditionalAgreements(contract.additionalAgreement);
        }
        toast.success("Данные успешно обновлены");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const sortResult = (items) => {

    return items.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA - dateB;
    });
  };

  const editAdditionalAgreementShowForm = (id) => {
    setShowFormAdditionalAgreement(true);
    const additionalAgreement = additionalAgreements.find(item => item.id === id);

    setNumberAdditionalAgreement(additionalAgreement.number);
    setDateAdditionalAgreement(additionalAgreement.date);
    setIdAdditionalAgreement(additionalAgreement.id);
  };

  const addContractForm = () => {
    setContractId(null);
    setShowForm(true);
  }

  const getFile = (id) => {
    setContractId(id);
    if (inputContractFile) {
      inputContractFile.current.click();
    }
  };

  const getAdditionalAgreementFile = (id) => {
    setIdAdditionalAgreement(id);
    if (inputAdditionalAgreementFile) {
      inputAdditionalAgreementFile.current.click();
    }
  };

  useEffect(() => {
    if (!contractFile || !contractId) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", contractFile);
    uploadContract(contractId, formData)
      .then((res) => {
        // setUpdData(res);
      })
      .finally(() => {
        setLoading(false);
        inputContractFile.current.value = null;
      });
  }, [contractFile]);

  useEffect(() => {
    if (!idAdditionalAgreement || !additionalAgreementFile || !contractId) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", additionalAgreementFile);
    uploadAdditionalAgreement(idAdditionalAgreement, contractId, formData)
      .then((res) => {
        // setUpdData(res);
      })
      .finally(() => {
        setLoading(false);
        inputAdditionalAgreementFile.current.value = null;
      });
  }, [additionalAgreementFile]);

  const renderContractTable = () => (
    <Table>
      <thead>
        <tr>
          <th>Наименование</th>
          <th className="right">Номер</th>
          <th>Дата</th>
          <th>Лимит кредита</th>
          <th>Дней отсрочки</th>
          <th>Основной</th>
          <th>Статус</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {contracts.map((item) => (
          <tr key={item.id} className={classNames({ inactive: !item.active })}>
            <td>{item.name}</td>
            <td className="right">{item.number}</td>
            <td>{dateFormat(item.date)}</td>
            <td>{item.creditSumMax}</td>
            <td>{item.delayDayCount}</td>
            <td className="center">
              {item.active && (
                <Checkbox
                  id={item.number}
                  value={item.id}
                  checked={item.main || false}
                  onChange={(val) => defaultContractHandler(val, item.id)}
                  disabled={!item.active}
                />
              )}
            </td>
            <td>
              <Select
                name="status"
                placeholder="Статус"
                className="react-select-container"
                classNamePrefix="react-select"
                options={STATUSES}
                value={STATUSES.find(
                  (val) => val.value === item.status
                )}
                onChange={(val) => statusHandler(val?.value, item.id)}
                isDisabled={!user.roles?.find((role) => role === "ROLE_ADMIN") && user.department !== "accounting"}
              />
            </td>
            <td className="table-action">
              {item.active && (
                <div className="table-actions-block">
                  <TableButton
                    Icon={Trash}
                    onClick={() => removeContractHandler(item.id)}
                  />

                  <TableButton
                    Icon={Edit}
                    onClick={() => editContractShowForm(item.id)}
                  />

                  <Button
                    onClick={() => getContractHandler(item.id)}
                    color="primary"
                    className="button-wide download"
                  >
                    Скачать рыбу (WORD)
                  </Button>

                  <Button color="primary" onClick={() => getFile(item.id)} className="ml-3" title="Загрузить скан">
                    <FontAwesomeIcon icon={faUpload} />
                  </Button>

                  {item.file &&
                    <Button color="primary" className="ml-3" href={`${process.env.REACT_APP_BASE_URL}${item.file}`} target="_blank" title="Скачать скан">
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  }

                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <div className="contracts">
      <Card className="mb-0">
        <CardBody className="pb-2">
          <FormTitle>Договоры</FormTitle>
        </CardBody>
      </Card>

      {loading && <MySpinner overlay />}

      <div className="contracts__table">
        <div className="contracts__header"></div>
        <Input
          type="file"
          id="fileContract"
          hidden
          innerRef={inputContractFile}
          onChange={(e) => setContractFile(e.target.files[0])}
        />

        <Input
          type="file"
          id="fileAdditionalAgreement"
          hidden
          innerRef={inputAdditionalAgreementFile}
          onChange={(e) => setAdditionalAgreementFile(e.target.files[0])}
        />
        {renderContractTable()}
        <div className="contracts__add">
          <Card className="contracts__add-form mb-0">
            <CardBody>
              {showForm ? (
                <>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Дата договора</Label>
                      <Input
                        type="date"
                        className="mb-1"
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Номер договора (оставить пустым, чтоб создался автоматом)</Label>
                      <Input
                        className="mb-1"
                        onChange={(e) => setNumber(e.target.value)}
                        value={number}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Наименование (оставить пустым, чтоб создалось автоматом)</Label>
                      <Input
                        className="mb-1"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Лимит кредита</Label>
                      <Input
                        className="mb-1"
                        onChange={(e) => setCreditSumMax(e.target.value)}
                        value={creditSumMax}
                        readOnly={additionalAgreements.length > 0 || !user.roles?.find((role) => role === "ALLOW_CHANGE_CREDIT")}
                      />
                    </Col>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Дней отсрочки</Label>
                      <Input
                        className="mb-1"
                        onChange={(e) => setDelayDayCount(e.target.value)}
                        value={delayDayCount}
                        readOnly={additionalAgreements.length > 0 || !user.roles?.find((role) => role === "ALLOW_CHANGE_CREDIT")}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Label className="text-sm-right">Доп соглашения</Label>
                      <Table className="additional">
                        <thead>
                          <tr>
                            <th>Номер</th>
                            <th>Дата</th>
                            <th>Лимит кредита</th>
                            <th>Дней отсрочки</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortResult(additionalAgreements).map(item =>
                            <tr key={item.id}>
                              <td>{item.number}</td>
                              <td>{dateFormat(item.date)}</td>
                              <td>{item.creditSumMax}</td>
                              <td>{item.delayDayCount}</td>
                              <td>
                                <div className="table-actions-block">
                                  <TableButton
                                    Icon={Trash}
                                    onClick={() => removeAdditionalAgreementHandler(item.id)}
                                  />
                                  <TableButton
                                    Icon={Edit}
                                    onClick={() => editAdditionalAgreementShowForm(item.id)}
                                  />
                                  <Button
                                    onClick={() => getAdditionalAgreementHandler(item)}
                                    color="primary"
                                    className="button-wide download"
                                  >
                                    Скачать рыбу (WORD)
                                  </Button>
                                  <Button color="primary" onClick={() => getAdditionalAgreementFile(item.id)} className="ml-3" title="Загрузить скан">
                                    <FontAwesomeIcon icon={faUpload} />
                                  </Button>
                                  {item.file &&
                                    <Button color="primary" className="ml-3" href={`${process.env.REACT_APP_BASE_DEV_URL}${item.file}`} target="_blank" title="Скачать скан">
                                      <FontAwesomeIcon icon={faDownload} />
                                    </Button>
                                  }
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>


                      {showFormAdditionalAgreement ? (<>

                        <Row>
                          <Col className="elem-field">
                            <Label className="text-sm-right">Номер</Label>
                            <Input
                              className="mb-1"
                              onChange={(e) => setNumberAdditionalAgreement(e.target.value)}
                              value={numberAdditionalAgreement}
                            />
                          </Col>

                          <Col className="elem-field">
                            <Label className="text-sm-right">Дата</Label>
                            <Input
                              type="date"
                              className="mb-1"
                              onChange={(e) => setDateAdditionalAgreement(e.target.value)}
                              value={dateAdditionalAgreement}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="elem-field">
                            <Label className="text-sm-right">Лимит кредита</Label>
                            <Input
                              className="mb-1"
                              onChange={(e) => setCreditSumMax(e.target.value)}
                              value={creditSumMax}
                            />
                          </Col>
                          <Col className="elem-field">
                            <Label className="text-sm-right">Дней отсрочки</Label>
                            <Input
                              className="mb-1"
                              onChange={(e) => setDelayDayCount(e.target.value)}
                              value={delayDayCount}
                            />
                          </Col>
                        </Row>
                        <div className="buttons">

                          <Button
                            color="primary"
                            onClick={addAdditionalAgreement}
                            className="button-wide contracts__save"
                          >
                            Сохранить доп соглашение
                          </Button>

                          <Button onClick={resetForm} className="button-wide">
                            Отменить
                          </Button>
                        </div>

                      </>) : <div className="buttons d-fl">
                        <Button onClick={() => setShowFormAdditionalAgreement(true)} className="button-wide ml-5">
                          Добавить доп соглашение
                        </Button>
                      </div>}

                    </Col>
                  </Row>

                  <div className="buttons">
                    <Button
                      color="primary"
                      onClick={addContractHandler}
                      className="button-wide contracts__save"
                    >
                      Сохранить договор
                    </Button>

                    <Button onClick={resetForm} className="button-wide">
                      Отменить
                    </Button>

                  </div>
                </>
              ) : (
                <Button onClick={addContractForm} color="primary">
                  Добавить договор
                </Button>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
