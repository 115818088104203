import React from "react";

import "./cut.scss";
import classNames from "classnames";

export const Cut = ({ children, width = false }) => {
  return (
    <div className={classNames("cut", { "width": width })}>
      <span>{children}</span>
      <div className="cut__hover">{children}</div>
    </div >
  );
};
