import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Trash } from "react-feather";

import { MySpinner } from "components/MySpinner";
import { Helmet } from "react-helmet";
import { HeaderPage } from "components/HeaderPage";
import { ActiveResource } from "components/ActiveResource";
import { TableButton } from "components/TableButton";
import { SelectProvider } from "components/SelectProvider";
import {
  createPriceList,
  getPriceListOne,
  updatePriceList,
} from "services/apiSupply";
import { validHandler } from "utils/validation";

import { VALIDATION } from "./validationFields";
import "./priceList.scss";
import { dateFormat, dateTimeFormat } from "utils/format";

const ENCODING_TYPES = [
  { label: "UTF-8", value: "utf8" },
  { label: "Windows 1251", value: "windows1251" },
  { label: "KOI8-R", value: "koi8r" },
];

export const PriceListOnePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [specification, setSpecification] = useState({});
  const [provider, setProvider] = useState(null);
  const [active, setActive] = useState(true);
  const [delivery, setDelivery] = useState(0);
  const [deliveryGarant, setDeliveryGarant] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);
  const [article, setArticle] = useState(0);
  const [brand, setBrand] = useState(0);
  const [name, setName] = useState(0);
  const [price, setPrice] = useState(0);
  const [exist, setExist] = useState(0);
  const [emails, setEmails] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [fileName, setFileName] = useState("");
  const [period, setPeriod] = useState(1);
  const [encoding, setEncoding] = useState("");
  const [log, setLog] = useState([]);
  const [startRowNum, setStartRowNum] = useState(1);
  const [forUpdate, setForUpdate] = useState(false);

  const getAndSetPriceList = () => {
    getPriceListOne(id)
      .then((res) => {
        setActive(res.active);
        setTitle(res.title);
        setSpecification(res.specification);
        setArticle(res.specification?.fields?.partNumber);
        setBrand(res.specification?.fields?.brand);
        setName(res.specification?.fields?.name);
        setPrice(res.specification?.fields?.cost);
        setExist(res.specification?.fields?.value);
        setEmails(res.specification?.email || []);
        setSubjects(res.specification?.subject || []);
        setFileNames(res.specification?.fileName || []);
        setStartRowNum(res.specification?.startRowNum);
        setProvider({ label: res.provider?.name, value: res.provider?.id });
        setDelivery(res.delivery);
        setDeliveryGarant(res.deliveryGarant);
        setPeriod(res.period);
        setEncoding(res.specification?.encoding || "utf8");
        setLog(res.log || []);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetPriceList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!needValid) return;
    const { errors } = validHandler(
      {
        title,
        article,
        brand,
        name,
        price,
        exist,
        provider: provider?.value,
        delivery,
        deliveryGarant,
        period,
      },
      VALIDATION
    );
    setErrors(errors);
    // eslint-disable-next-line
  }, [
    title,
    article,
    brand,
    name,
    price,
    exist,
    provider?.value,
    delivery,
    deliveryGarant,
    period,
  ]);

  const updateHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        title,
        article,
        brand,
        name,
        price,
        exist,
        provider: provider?.value,
        delivery,
        deliveryGarant,
        period,
      },
      VALIDATION
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    if (!id) {
      createPriceList({
        provider: provider?.value,
        title,
        delivery,
        deliveryGarant,
        specification: {
          email: emails,
          fields: {
            cost: price,
            name,
            brand,
            value: exist,
            partNumber: article,
          },
          subject: subjects,
          fileName: fileNames,
          encoding,
          startRowNum
        },
        period,
      })
        .then((res) => {
          history.push(res.id);
          toast.success("Прайс лист успешно создан");
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      updatePriceList({
        id,
        active,
        provider: provider?.value,
        title,
        delivery,
        deliveryGarant,
        specification: {
          email: emails,
          fields: {
            cost: price,
            name,
            brand,
            value: exist,
            partNumber: article,
          },
          subject: subjects,
          fileName: fileNames,
          encoding,
          startRowNum
        },
        period,
      })
        .then((res) => {
          setActive(res.active);
          setTitle(res.title);
          setSpecification(res.specification);
          setArticle(res.specification?.fields?.partNumber);
          setBrand(res.specification?.fields?.brand);
          setName(res.specification?.fields?.name);
          setPrice(res.specification?.fields?.cost);
          setExist(res.specification?.fields?.value);
          setEmails(res.specification?.email || []);
          setSubjects(res.specification?.subject || []);
          setFileNames(res.specification?.fileName || []);
          setStartRowNum(res.specification?.startRowNum);
          setProvider({ label: res.provider?.name, value: res.provider?.id });
          setDelivery(res.delivery);
          setDeliveryGarant(res.deliveryGarant);
          setPeriod(res.period);

          toast.success("Изменения успешно сохранены");
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const setForUpdatePriceList = () => {
    updatePriceList({
      id,
      forUpdate: true
    })
      .then((res) => {
        setForUpdate(res.forUpdate);
        toast.success("Изменения успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const removeSubjectHandler = (i) => {
    const newSubjects = [...subjects];
    newSubjects.splice(i, 1);
    setSubjects(newSubjects);
  };

  const removeEmailHandler = (i) => {
    const newEmails = [...emails];
    newEmails.splice(i, 1);
    setEmails(newEmails);
  };

  const removeFileNameHandler = (i) => {
    const newFileNames = [...fileNames];
    newFileNames.splice(i, 1);
    setFileNames(newFileNames);
  };

  const addEmailHandler = () => {
    if (!email) return;
    setEmails((prev) => [...prev, email]);
    setEmail("");
  };

  const addSubjectHandler = () => {
    if (!subject) return;
    setSubjects((prev) => [...prev, subject]);
    setSubject("");
  };

  const addFileNameHandler = () => {
    if (!fileName) return;
    setFileNames((prev) => [...prev, fileName]);
    setFileName("");
  };

  return (
    <Container fluid className="p-0 priceList">
      <Helmet>
        <title>Прайс лист {title || "новый"}</title>
      </Helmet>

      <HeaderPage title={`Прайс лист ${title || "новый"}`}>
        <ActiveResource
          active={active}
          checkboxHandler={(e) => setActive(e.target.checked)}
        />
      </HeaderPage>

      {loading && <MySpinner overlay />}

      <Card className="main-form mb-0">
        <CardBody>
          <Form>
            <Row>
              <Col md="8" className="elem-field">
                <Button
                  color="primary"
                  onClick={setForUpdatePriceList}
                  className="button-wide mb-3"
                  disabled={forUpdate}
                >
                  Обновить по последнему письму
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="8" className="elem-field">
                <Label className="text-sm-right">Наименование</Label>
                <Input
                  name="title"
                  placeholder="Наименование"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  invalid={needValid && errors.hasOwnProperty("title")}
                />
              </Col>
            </Row>
            <Row>
              <Col md="8" className="elem-field">
                <Label className="text-sm-right">Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="elem-field">
                <Label className="text-sm-right">Срок поставки, ч</Label>
                <Input
                  name="title"
                  placeholder="Срок поставки, ч"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                  invalid={needValid && errors.hasOwnProperty("delivery")}
                />
              </Col>
              <Col md="6" className="elem-field">
                <Label className="text-sm-right">Вероятность поставки, %</Label>
                <Input
                  name="title"
                  placeholder="Вероятность поставки"
                  value={deliveryGarant}
                  onChange={(e) => setDeliveryGarant(e.target.value)}
                  invalid={needValid && errors.hasOwnProperty("deliveryGarant")}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="elem-field">
                <Label className="text-sm-right">
                  Периодичность обновления прайса, дн
                </Label>
                <Input
                  name="period"
                  placeholder="Периодичность обновления прайса"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  invalid={needValid && errors.hasOwnProperty("period")}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md="12" className="elem-field">
                <h5>Email-ы, с которых приходят прайсы</h5>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="elem-field">
                <table className="table table-hover">
                  <tbody>
                    {emails.map((val, i) => (
                      <tr key={val}>
                        <td>{val}</td>
                        <td>
                          <TableButton
                            Icon={Trash}
                            onClick={() => removeEmailHandler(i)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <InputGroup>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    invalid={needValid && errors.hasOwnProperty("email")}
                  />
                  <Button onClick={addEmailHandler}>Добавить</Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="12" className="elem-field">
                <h5>Ключевые слова в теме письма</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="elem-field">
                <table className="table table-hover">
                  <tbody>
                    {subjects.map((val, i) => (
                      <tr key={val}>
                        <td>{val}</td>
                        <td>
                          <TableButton
                            Icon={Trash}
                            onClick={() => removeSubjectHandler(i)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <InputGroup>
                  <Input
                    name="subject"
                    placeholder="Тема письма"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    invalid={needValid && errors.hasOwnProperty("subject")}
                  />
                  <Button onClick={addSubjectHandler}>Добавить</Button>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="12" className="elem-field">
                <h5>Название файла</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="elem-field">
                <table className="table table-hover">
                  <tbody>
                    {fileNames.map((val, i) => (
                      <tr key={val}>
                        <td>{val}</td>
                        <td>
                          <TableButton
                            Icon={Trash}
                            onClick={() => removeFileNameHandler(i)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <InputGroup>
                  <Input
                    name="fileName"
                    placeholder="Название файла"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    invalid={needValid && errors.hasOwnProperty("fileName")}
                  />
                  <Button onClick={addFileNameHandler}>Добавить</Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="text-sm-right">Кодировка файла</Label>
                <Select
                  name="encoding"
                  placeholder="Выберите кодировку файла"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ENCODING_TYPES}
                  value={ENCODING_TYPES.find((type) => encoding === type.value)}
                  onChange={(val) => setEncoding((prev) => val.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="text-sm-right">Строка, с которой начинается чтение</Label>
                <Input
                  name="startRowNum"
                  placeholder="Укажите номер строки"
                  value={startRowNum}
                  onChange={(e) => setStartRowNum(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="12" className="elem-field">
                <h5>Настройка полей прайса</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="elem-field">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Поле</th>
                      <th>Столбец</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Артикул</td>
                      <td>
                        <Input
                          name="article"
                          type="number"
                          placeholder="Артикул"
                          value={article}
                          onChange={(e) => setArticle(e.target.value)}
                          invalid={
                            needValid && errors.hasOwnProperty("article")
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Бренд</td>
                      <td>
                        <Input
                          name="brand"
                          type="number"
                          placeholder="Бренд"
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                          invalid={needValid && errors.hasOwnProperty("brand")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Наименование</td>
                      <td>
                        <Input
                          name="name"
                          type="number"
                          placeholder="Бренд"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          invalid={needValid && errors.hasOwnProperty("name")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Цена</td>
                      <td>
                        <Input
                          name="price"
                          type="number"
                          placeholder="Цена"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          invalid={needValid && errors.hasOwnProperty("price")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Кол-во</td>
                      <td>
                        <Input
                          name="exist"
                          type="number"
                          placeholder="Наличие"
                          value={exist}
                          onChange={(e) => setExist(e.target.value)}
                          invalid={needValid && errors.hasOwnProperty("exist")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md="12" className="elem-field">
                <h5>История загрузки последнего прайса</h5>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="elem-field">
                <table className="table table-hover">
                  <tbody>
                    {log.map(item =>
                      <tr key={item.message}>
                        <td>{dateTimeFormat(item.date)}</td>
                        <td>{item.message}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card >

      <div className="priceList__save">
        <Button
          color="primary"
          onClick={updateHandler}
          className="button-wide mr-3"
        >
          Сохранить
        </Button>
      </div>
    </Container >
  );
};
