import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { CornerRightDown as CornerRightDownIcon } from "react-feather";
import classNames from "classnames";

import {
  getNomenclature,
  importNomenclature,
  priceMailing,
  brandsSuggestion,
  downloadNomenclatureToExcel,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { NomenclatureModal } from "features/Nomenclature/NomenclatureModal";
import { NomenclatureTable } from "features/Nomenclature/NomenclatureTable";
import { Checkbox } from "components/Checkbox";
import { SelectSearch } from "components/SelectSearch";

import "./nomenclature.scss";
import { dateTimeFormat } from "utils/format";

const SORT_FIELDS = [
  {
    label: "Дата добавления ↑",
    value: "createdAt_asc",
    by: "createdAt",
    dir: "asc",
  },
  {
    label: "Дата добавления ↓",
    value: "createdAt_desc",
    by: "createdAt",
    dir: "desc",
  },
  {
    label: "Активность ↓",
    value: "active_asc",
    by: "active",
    dir: "asc",
  },
  {
    label: "Активность ↑",
    value: "active_desc",
    by: "active",
    dir: "desc",
  },
];

export const ABC = [
  { label: "A", value: 1 },
  { label: "B", value: 2 },
  { label: "C", value: 3 },
  { label: "D", value: 4 }
];

export const NomenclaturePage = () => {
  const inputFile = useRef(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [nomenclature, setNomenclature] = useState([]);
  const [nomenclatureCount, setNomenclatureCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [minStockNotEmpty, setMinStockNotEmpty] = useState(query.get("minStockNotEmpty") || false);
  const [searchType, setSearchType] = useState(query.get("searchType") || null);
  const [inStock, setInStock] = useState(query.get("inStock") || false);
  const [barcode, setBarcode] = useState(query.get("barcode") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
      (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
    )
    : { value: "", label: "" };

  const [sort, setSort] = useState(sortByDefault);
  const [brand, setBrand] = useState(
    query.get("brand")
      ? { label: query.get("brandName"), value: query.get("brand") }
      : null
  );
  const [file, setFile] = useState(null);
  const [wrongItems, setWrongItems] = useState([]);

  const [filterAbc, setFilterAbc] = useState(
    localStorage.getItem("filterAbc")
      ? JSON.parse(localStorage.getItem("filterAbc"))
      : []
  );
  const [filterStatus, setFilterStatus] = useState(
    localStorage.getItem("filterNomenclatureStatus")
      ? JSON.parse(localStorage.getItem("filterNomenclatureStatus"))
      : []
  );

  const [compatibility, setCompatibility] = useState(
    query.get("compatibility")
      ? { label: query.get("compatibilityName"), value: query.get("compatibility") }
      : null
  );

  const getAndSetNomenclature = () => {
    getNomenclature({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
      minStockNotEmpty,
      brand: brand?.value,
      searchType,
      inStock,
      barcode,
      abc: filterAbc.map(val => val.value),
      status: filterStatus.map(val => val.value),
      compatibility: compatibility?.value
    })
      .then((res) => {
        setNomenclature(res.items);
        setNomenclatureCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (minStockNotEmpty) {
      url.append("minStockNotEmpty", minStockNotEmpty);
    }

    if (searchType) {
      url.append("searchType", searchType);
    }

    if (inStock) {
      url.append("inStock", inStock);
    }

    if (barcode) {
      url.append("barcode", barcode);
    }

    if (sort.by && sort?.dir) {
      url.append("sortBy", sort?.by);
      url.append("sortDir", sort?.dir);
    }

    if (brand?.value) {
      url.append("brand", brand?.value);
      url.append("brandName", brand?.label);
    }

    if (compatibility?.value) {
      url.append("compatibility", compatibility?.value);
      url.append("compatibilityName", compatibility?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    search,
    sort,
    minStockNotEmpty,
    brand,
    searchType,
    inStock,
    barcode,
    filterAbc,
    filterStatus,
    compatibility
  ]);

  useEffect(() => {
    setLoading(true);
    getAndSetNomenclature();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetNomenclature();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, minStockNotEmpty, brand, searchType, inStock, filterAbc, filterStatus, compatibility]);

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetNomenclature();
  };

  const handleKeypress = (e) => {
    console.log(e);
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const sendPriceList = () => {
    setLoading(true);
    priceMailing()
      .then((res) => {
        toast.success("Прайс успешно отправлен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const isSearch = () => {
    if (search || brand || minStockNotEmpty || searchType || inStock || barcode || filterAbc || filterStatus || compatibility) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
    setBrand(null);
    setCompatibility(null);
    setMinStockNotEmpty(false);
    setSearchType(null);
    setInStock(false);
    setFilterAbc([]);
    setFilterStatus([]);
    setBarcode("");
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "sort":
        setSort(val);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    importNomenclature(formData)
      .then((res) => {
        setWrongItems(res);
        getAndSetNomenclature();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {
        toast.success("Загрузка успешно выполнена");
        inputFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const getOrderFile = () => {
    if (inputFile) {
      inputFile.current.click();
    }
  };

  const download = () => {
    setLoading(true);

    downloadNomenclatureToExcel({
      search,
      sort,
      minStockNotEmpty,
      brand: brand?.value,
      searchType,
      inStock,
      barcode,
      abc: filterAbc.map(val => val.value),
      status: filterStatus.map(val => val.value),
      compatibility: compatibility?.value
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Номенклатура_${dateTimeFormat(new Date())}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container fluid className="p-0 nomenclature">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Номенклатура</h1>
          <Form className="mb-3">
            <Row>
              <Col md={2}>
                <Label>Артикул, uuid</Label>
                <div className="filter__type-date">
                  <button
                    className={classNames("filter__type-date-item", {
                      active: searchType === null,
                    })}
                    type="button"
                    onClick={() => setSearchType(null)}
                  >
                    С начала
                  </button>
                  <button
                    className={classNames("filter__type-date-item", {
                      active: searchType === "full",
                    })}
                    type="button"
                    onClick={() => setSearchType("full")}
                  >
                    Полное совпадение
                  </button>
                </div>
                <Input
                  name="search"
                  placeholder="Артикул, uuid"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Штрихкод</Label>
                <Input
                  name="barcode"
                  placeholder="Штрихкод"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col md={1}>
                <Label>С мин.ост</Label>
                <Checkbox
                  name="minStockNotEmpty"
                  onChange={(e) => setMinStockNotEmpty(e.target.checked)}
                  checked={minStockNotEmpty}
                />
              </Col>
              <Col md={2}>
                <Label>ABC</Label>
                <Select
                  isMulti
                  name="abc"
                  placeholder="ABC"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ABC}
                  value={ABC.map((val) => {
                    for (let f of filterAbc) {
                      if (f.value === val.value) {
                        return val;
                      }
                    }
                    return false;
                  })}
                  onChange={(val) => {
                    localStorage.setItem("filterAbc", JSON.stringify(val));
                    setFilterAbc(val);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Статус</Label>
                <Select
                  isMulti
                  name="status"
                  placeholder="Статус"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ABC.filter(v => v.value === 1 || v.value === 4)}
                  value={ABC.map((val) => {
                    for (let f of filterStatus) {
                      if (f.value === val.value) {
                        return val;
                      }
                    }
                    return false;
                  })}
                  onChange={(val) => {
                    localStorage.setItem("filterNomenclatureStatus", JSON.stringify(val));
                    setFilterStatus(val);
                  }}
                />
              </Col>
              <Col md={1}>
                <Label>В наличии</Label>
                <Checkbox
                  name="inStock"
                  onChange={(e) => setInStock(e.target.checked)}
                  checked={inStock}
                />
              </Col>
              <Col md={3}>
                <Label>Бренд</Label>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brand}
                  setValue={setBrand}
                  isClearable
                />
              </Col>

              <Col md={3}>
                <Label>Применяемость</Label>
                <SelectSearch
                  name="compatibility"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={compatibility}
                  setValue={setCompatibility}
                  isClearable
                />
              </Col>

              <Col md={2}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>Сортировка</Label>
                <InputGroup>
                  <Select
                    name="sort"
                    placeholder="Сортировка"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={SORT_FIELDS}
                    value={sort}
                    onChange={selectHandler}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row className="mb-2">
            <Col сclassName="d-flex">
              <Button onClick={() => setOpenModal(true)} color="primary">
                Добавить номенклатуру
              </Button>
              <Button onClick={sendPriceList} color="primary" className="ml-2">
                Отправить прайс-лист
              </Button>

              <Button onClick={download} className="ml-2">
                Скачать
              </Button>

            </Col>
            <Col сclassName="d-flex">
              <Button onClick={getOrderFile} className="button-wide mr-2">
                Импорт
              </Button>
              <a
                href="https://api.lider-truck.ru/files/templates/templateImportNomenclature.xlsx"
                className="btn btn-secondary button-wide"
              >
                Скачать шаблон
                <CornerRightDownIcon size={15} />
              </a>
              <Input
                type="file"
                id="order"
                hidden
                innerRef={inputFile}
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Col>
          </Row>
          {wrongItems.length > 0 && (
            <Alert color="warning">
              <ul>
                {wrongItems.map((val) => (
                  <li>
                    {val.index} - {val.article} - {val.brand}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </CardBody>
      </Card>

      <NomenclatureTable
        nomenclature={nomenclature}
        getAndSetNomenclature={getAndSetNomenclature}
        setLoading={setLoading}
        setOpenModal={setOpenModal}
        setId={setId}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={nomenclatureCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>

      <NomenclatureModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        getAndSetNomenclature={getAndSetNomenclature}
      />
    </Container >
  );
};
