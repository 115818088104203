import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import {
  downloadAnaliticsSale,
  generateAnaliticsSale,
  getAnaliticsSale,
  getProgress,
  getProgressAnaliticsSale,
} from "services/apiAnalitics";
import { AnaliticsSaleTable } from "features/AnaliticsSale/AnaliticsSaleTable";
import { SelectSearch } from "components/SelectSearch";
import { brandsSuggestion, getAdminsForSelect } from "services/api";
import { dateFormatU, dateTimeFormat, priceFormat } from "utils/format";
import { SelectProvider } from "components/SelectProvider";
import { getWarehouses } from "services/apiWarehouse";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { MySpinner } from "components/MySpinner";
import { Checkbox } from "components/Checkbox";
import { useUser } from "features/Users/UserContext";
import { PAYMENTS } from "pages/orderOne";

import "./analiticsSale.scss";

const SORT_FIELDS = [
  { label: "Фикс прайс ↑", value: "fixPrice_asc", by: "fixPrice", dir: "asc" },
  {
    label: "Фикс прайс ↓",
    value: "fixPrice_desc",
    by: "fixPrice",
    dir: "desc",
  },
];

const CONTEXT = [
  { label: "По артикулам", value: "nomenclature" },
  { label: "По брендам", value: "brands" },
  { label: "По поставщикам", value: "providers" },
  { label: "По складам", value: "warehouses" },
  { label: "По подразделениям", value: "company" },
  { label: "По клиентам", value: "customers" },
  { label: "По кладовщикам", value: "storeKeeper" },
  { label: "По менеджерам", value: "admin" },
  { label: "По документам", value: "documents" },
];

const WITH_CORR = [
  { label: "Только с корректировками", value: "1" },
  { label: "Без корректировок", value: "0" },
];

const TYPES = [
  { label: "Юр лица", value: "2" },
  { label: "Физ лица", value: "1" },
];

registerLocale("ru", ru);

export const AnaliticsSalePage = () => {
  const { admins } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [analiticsSale, setAnaliticsSale] = useState([]);
  const [analiticsSaleCount, setAnaliticsSaleCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({});
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [withCorr, setWithCorr] = useState(query.get("withCorr")
    ? { label: query.get("withCorrName"), value: query.get("withCorr") }
    : null
  );
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : moment().startOf('year').toDate()
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [priceStart, setPriceStart] = useState(
    query.get("priceStart") || ""
  );
  const [priceEnd, setPriceEnd] = useState(
    query.get("priceEnd") || ""
  );
  const [intervalBalance, setIntervalBalance] = useState(null);

  const queryBrands = query.get("brand") ?
    query.get("brand").includes(',') ? query.get("brand").split(',') : [query.get("brand")]
    : [];

  const [brands, setBrands] = useState(queryBrands.length > 0
    ? queryBrands.map(val => {
      const valBrand = val.split('|');
      return { label: valBrand[1], value: valBrand[0] }
    })
    : []);

  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );
  const [customer, setCustomer] = useState(
    query.get("customer")
      ? { label: query.get("customerName"), value: query.get("customer") }
      : null
  );
  const [storeKeeper, setStoreKeeper] = useState(
    query.get("storeKeeper")
      ? { label: query.get("storeKeeperName"), value: query.get("storeKeeper") }
      : null
  );
  const [admin, setAdmin] = useState(
    query.get("admin")
      ? { label: query.get("adminName"), value: query.get("admin") }
      : null
  );
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
      (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
    )
    : null;

  const [warehouse, setWarehouse] = useState(
    query.get("warehouse")
      ? { label: query.get("warehouseName"), value: query.get("warehouse") }
      : null
  );
  const [payment, setPayment] = useState(
    query.get("payment")
      ? { label: query.get("paymentName"), value: query.get("payment") }
      : null
  );

  const [warehouses, setWarehouses] = useState([]);

  const [sort, setSort] = useState(sortByDefault);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const [totalCount, setTotalCount] = useState(0);
  const [totalSumm, setTotalSumm] = useState(0);
  const [totalCorrCount, setTotalCorrCount] = useState(0);
  const [totalCorrSumm, setTotalCorrSumm] = useState(0);

  const [totalCostPrice, setTotalCostPrice] = useState(0);
  const [totalAddExpenses, setTotalAddExpenses] = useState(0);
  const [totalGrossProfit, setTotalGrossProfit] = useState(0);
  const [totalMargin, setTotalMargin] = useState(0);
  const [withAnalogs, setWithAnalogs] = useState(
    query.get("withAnalogs") || false
  );
  const [type, setType] = useState(
    query.get("type")
      ? { label: query.get("typeName"), value: query.get("type") }
      : null
  );
  const [context, setContext] = useState(
    query.get("context")
      ? { label: query.get("contextName"), value: query.get("context") }
      : null
  );
  const [lastUpdate, setLastUpdate] = useState(null);

  const getAndSetAnaliticsSale = () => {
    getAnaliticsSale({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      priceStart,
      priceEnd,
      brand: brands.map(brand => brand?.value),
      sort,
      provider: provider?.value,
      warehouse: warehouse?.value,
      payment: payment?.value,
      context: context?.value,
      customer: customer?.value,
      storeKeeper: storeKeeper?.value,
      withAnalogs: withAnalogs ? 1 : 0,
      admin: admin?.value,
      withCorr: withCorr?.value,
      type: type?.value
    })
      .then((res) => {
        setAnaliticsSale(res.items);
        setAnaliticsSaleCount(res.totalItems);
        setTotalCount(res.totalCount);
        setTotalSumm(res.totalSumm);
        setTotalCostPrice(res.totalCostPrice);
        setTotalAddExpenses(res.totalAddExpenses);
        setTotalMargin(res.totalMargin);
        setTotalGrossProfit(res.totalGrossProfit);
        setLastUpdate(res.dateLastUpdate);
        setTotalCorrCount(res.totalCorrCount);
        setTotalCorrSumm(res.totalCorrSumm);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetAnaliticsSale();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (sort?.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (customer?.value) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (storeKeeper?.value) {
      url.append("storeKeeper", storeKeeper?.value);
      url.append("storeKeeperName", storeKeeper?.label);
    }

    if (admin?.value) {
      url.append("admin", admin?.value);
      url.append("adminName", admin?.label);
    }

    if (brands.length) {
      url.append("brand", brands.map(val => `${val.value}|${val.label}`).join(','));
    }

    if (provider?.value) {
      url.append("provider", provider?.value);
      url.append("providerName", provider?.label);
    }

    if (warehouse?.value) {
      url.append("warehouse", warehouse?.value);
      url.append("warehouseName", warehouse?.label);
    }

    if (payment?.value) {
      url.append("payment", payment?.value);
      url.append("paymentName", payment?.label);
    }

    if (context?.value) {
      url.append("context", context?.value);
      url.append("contextName", context?.label);
    }

    if (withCorr?.value) {
      url.append("withCorr", withCorr?.value);
      url.append("withCorrName", withCorr?.label);
    }

    if (type?.value) {
      url.append("type", type?.value);
      url.append("typeName", type?.label);
    }

    if (withAnalogs) {
      url.append("withAnalogs", withAnalogs);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }
  }, [
    currentPage,
    pageSize,
    search,
    dateStart,
    dateEnd,
    priceStart,
    priceEnd,
    brands,
    sort,
    provider?.value,
    customer?.value,
    storeKeeper?.value,
    warehouse?.value,
    admin?.value,
    context,
    withAnalogs,
    withCorr,
    type?.value,
    payment?.value
  ]);

  const isSearch = () => {
    if (
      search ||
      dateStart ||
      dateEnd ||
      priceStart ||
      priceEnd ||
      brands.length > 0 ||
      provider?.value ||
      customer?.value ||
      storeKeeper?.value ||
      admin?.value ||
      warehouse?.value ||
      payment?.value ||
      context ||
      withAnalogs ||
      withCorr ||
      type
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetAnaliticsSale();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAnaliticsSale();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    dateStart,
    dateEnd,
    priceStart,
    priceEnd,
    brands,
    sort,
    provider?.value,
    customer?.value,
    storeKeeper?.value,
    warehouse?.value,
    payment?.value,
    admin?.value,
    context,
    withAnalogs,
    withCorr,
    type?.value
  ]);

  const getAndSetProgress = () => {
    getProgressAnaliticsSale().then((res) => {
      setProgress(res);

      if (typeof res?.progress !== "undefined") {
        setLoadingGenerate(true);
      } else {
        if (loadingGenerate) {
          toast.success("Генерация отчета завершена");
          setLoadingGenerate(false);
        }
      }
    });
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetProgress();
    getAndSetWarehouses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilter = () => {
    setSearch("");
    setDateStart("");
    setDateEnd("");
    setPriceStart("");
    setPriceEnd("");
    setBrands([]);
    setProvider(null);
    setCustomer(null);
    setStoreKeeper(null);
    setAdmin(null);
    setWarehouse(null);
    setPayment(null);
    setContext(null);
    setWithAnalogs(false);
    setWithCorr(null);
    setType(null);
  };

  const generateAnaliticsSaleHandler = () => {
    setLoadingGenerate(true);
    toast.success("Генерация отчета запущена");
    generateAnaliticsSale()
      .then((res) => { })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  useEffect(() => {
    if (loadingGenerate) {
      setIntervalBalance(
        setInterval(() => {
          getAndSetProgress();
        }, 3000)
      );
    } else {
      clearInterval(intervalBalance);
      setIntervalBalance(null);
      setLoadingGenerate(false);
    }

    return () => {
      clearInterval(intervalBalance);
    };
  }, [loadingGenerate]);

  const downloadHandler = () => {
    setLoading(true);
    downloadAnaliticsSale({
      search,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      priceStart,
      priceEnd,
      brand: brands.map(brand => brand?.value),
      provider: provider?.value,
      warehouse: warehouse?.value,
      context: context?.value,
      customer: customer?.value,
      storeKeeper: storeKeeper?.value,
      admin: admin?.value,
      withAnalogs: withAnalogs ? 1 : 0,
      withCorr: withCorr?.value,
      type: type?.value,
      payment: payment?.value,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Отчет по продажам.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(e => {
        if (e.response?.status === 500) {
          toast.error("Ошибка 500");
        } else {
          toast.error(e.response?.data?.message)
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSort = (field, order) => {
    setSort({
      by: field,
      dir: order,
    });
  };

  return (
    <Container fluid className="p-0 analitics-sale">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Отчет по продажам</h1>

          <Form className="mb-3">
            <Row>
              <Col md={2}>
                <Label>Артикул</Label>
                <div className="filter__with-analog">
                  <Checkbox
                    name="withAnalogs"
                    checked={withAnalogs}
                    onChange={(e) => setWithAnalogs(e.target.checked)}
                  />
                  С аналогами
                </div>

                <Input
                  name="search"
                  placeholder="По артикулу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={3}>
                <Label>Дата</Label>

                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>

              <Col md={2}>
                <Label>Цена</Label>

                <InputGroup className="daterange">
                  <Input
                    name="priceStart"
                    placeholder="От"
                    value={priceStart}
                    onChange={(e) => setPriceStart(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  <Input
                    name="priceEnd"
                    placeholder="До"
                    value={priceEnd}
                    onChange={(e) => setPriceEnd(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                </InputGroup>
              </Col>

              <Col md={3}>
                <Label>Бренд</Label>
                <SelectSearch
                  isMulti
                  isClearable
                  name="brands"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brands}
                  setValue={setBrands}
                />
              </Col>
              <Col md={2}>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
              <Col md={3}>
                <Label>Склад</Label>
                <Select
                  isClearable
                  name="warehouse"
                  placeholder="Выберите склад"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={warehouses.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  value={warehouse}
                  onChange={setWarehouse}
                />
              </Col>
              <Col md={3}>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>
              <Col md={3}>
                <Label>Кладовщик</Label>
                <Select
                  isClearable
                  name="storeKeeper"
                  placeholder="Выберите кладовщика"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins.filter(
                    (item) => item.department === "warehouse"
                  )}
                  value={storeKeeper}
                  onChange={setStoreKeeper}
                />
              </Col>
              <Col md={3}>
                <Label>Менеджер</Label>
                <Select
                  isClearable
                  name="admin"
                  placeholder="Выберите менеджера"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={setAdmin}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>С учетом корректировок</Label>
                <Select
                  isClearable
                  name="withCorr"
                  placeholder="Выберите вариант"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={WITH_CORR}
                  value={withCorr}
                  onChange={setWithCorr}
                />
              </Col>
              <Col md={3}>
                <Label>Тип клиента</Label>
                <Select
                  isClearable
                  name="type"
                  placeholder="Выберите вариант"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={TYPES}
                  value={type}
                  onChange={setType}
                />
              </Col>
              <Col md={3}>
                <Label>Тип платежа</Label>
                <Select
                  isClearable
                  name="type"
                  placeholder="Выберите вариант"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={PAYMENTS}
                  value={payment}
                  onChange={setPayment}
                />
              </Col>
              <Col md={3}>
                <Label>В контексте</Label>
                <Select
                  isClearable
                  name="context"
                  placeholder="Выберите контекст"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={CONTEXT}
                  value={context}
                  onChange={setContext}
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="3" className="d-flex flex-column align-items-start">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={generateAnaliticsSaleHandler}
                disabled={loadingGenerate}
              >
                Сгенерировать
                {loadingGenerate && (
                  <div className="spinner-wrapper">
                    <Spinner color="danger" size="sm" />
                  </div>
                )}
              </Button>
              {loadingGenerate && (
                <>Процесс генерации: {progress?.progress} %</>
              )}
            </Col>

            <Col md="3">
              <Button
                color="primary"
                className="button-wide btn btn-primary"
                onClick={downloadHandler}
              >
                Скачать
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="3">Дата обновления: {dateTimeFormat(lastUpdate)}</Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Кол-во общее</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalCount)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Объем продаж</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalSumm)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Кол-во корректировок</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalCorrCount)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Сумма корректировок</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalCorrSumm)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Себестоимость</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalCostPrice)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Доп расходы</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalAddExpenses)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Валовая прибыль</Label>
            </Col>
            <Col md="4">
              <b>{priceFormat(totalGrossProfit)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <Label className="text-sm-right">Маржинальность</Label>
            </Col>
            <Col md="4">
              <b>{totalMargin} %</b>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <AnaliticsSaleTable
          analiticsSale={analiticsSale}
          onSort={onSort}
          context={context}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={analiticsSaleCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
