import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import { MySpinner } from "components/MySpinner";
import { StockTable } from "features/Stock/StockTable";
import { getStock, getWarehouses } from "services/apiWarehouse";
import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import useQuery from "utils/useQuery";

import "./stock.scss";
import { dateFormatU } from "utils/format";
registerLocale("ru", ru);

export const StockPage = () => {
  const history = useHistory();
  const query = useQuery(useLocation);
  const { pathname } = useLocation();
  const [stock, setStock] = useState([]);
  const [balanceByDocuments, setBalanceByDocuments] = useState([]);
  const [balanceByWarehouse, setBalanceByWarehouse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState(query.get("article") || "");
  const [brand, setBrand] = useState(query.get("brand") || "");
  const [filterWarehouses, setFilterWarehouses] = useState(
    localStorage.getItem("warehouse")
      ? JSON.parse(localStorage.getItem("warehouse"))
      : null
  );

  const [nomenclature, setNomenclature] = useState(
    query.get("nomenclature") || ""
  );
  const [warehouses, setWarehouses] = useState([]);

  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );

  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );

  const getAndSetStock = () => {
    setLoading(true);
    getStock({
      nomenclature,
      warehouse: filterWarehouses?.value,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
    })
      .then((res) => {
        setStock(res.stock || []);
        setBalanceByDocuments(res.balanceByDocuments || []);
        setBalanceByWarehouse(res.balanceByWarehouse || []);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then((res) =>
        setWarehouses(
          res.map((item) => {
            return { label: item.name, value: item.id };
          })
        )
      )
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (nomenclature) {
      url.append("nomenclature", nomenclature);
    }

    if (article) {
      url.append("article", article);
    }

    if (brand) {
      url.append("brand", brand);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    getAndSetWarehouses();
    if (!nomenclature) return;
    getAndSetStock();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomenclature, filterWarehouses, dateStart, dateEnd]);

  const selectWarehouseHandler = (val, res) => {
    localStorage.setItem("warehouse", JSON.stringify(val));
    setFilterWarehouses(val);
  };

  const calcInStock = () => {
    return balanceByWarehouse.reduce(
      (acc, val) => acc + parseInt(val.balance?.inStock),
      0
    );
  };

  const calcInReserve = () => {
    return balanceByWarehouse.reduce(
      (acc, val) => acc + parseInt(val.balance?.inReserve),
      0
    );
  };

  return (
    <Container fluid className="p-0 stock">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Движения товаров</h1>

          <Form className="mb-3">
            <Row>
              <Col lg="4">
                <Label>Артикул</Label>
                <AutocompleteArticle
                  article={article}
                  brand={brand}
                  setArticle={setArticle}
                  setBrand={({ id, name }) => setBrand(name)}
                  setNomenclature={setNomenclature}
                />
              </Col>
              <Col lg="2" className="with-label">
                {article && brand && (
                  <Button color="primary" onClick={getAndSetStock}>
                    Обновить
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <b>{brand}</b>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Label>Склад</Label>
                <Select
                  name="warehouse"
                  placeholder="Склад"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={warehouses}
                  value={filterWarehouses}
                  onChange={selectWarehouseHandler}
                  isClearable
                />
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form>

          {balanceByWarehouse.length > 0 ? (
            <Row>
              <Col md={5}>
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ width: "60%" }}>Склад</td>
                      <td style={{ width: "20%" }}>Ост.</td>
                      <td style={{ width: "20%" }}>Рез.</td>
                    </tr>
                    {balanceByWarehouse.map((val) => (
                      <tr key={val.warehouse?.id}>
                        <td>{val.warehouse?.name}</td>
                        <td>{val.balance?.inStock}</td>
                        <td>{val.balance?.inReserve}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Итого:</td>
                      <td>
                        <b>{calcInStock()}</b>
                      </td>
                      <td>
                        <b>{calcInReserve()}</b>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="4">
                <h5>Остатки</h5>
                <div>
                  Свободный остаток: <b>0</b>
                </div>

                <div>
                  В резерве: <b>0</b>
                </div>
              </Col>
            </Row>
          )}

          {balanceByDocuments.length > 0 && (
            <Row>
              <Col lg="4">
                <h5>Свободные остатки по входящим документам</h5>
                {balanceByDocuments.map((val) => (
                  <div key={val.id}>
                    {val.document}: <b>{val.count}</b>
                  </div>
                ))}
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      <StockTable
        stock={stock}
        nomenclature={nomenclature}
        getAndSetStock={getAndSetStock}
        warehouse={filterWarehouses?.value}
      />
    </Container>
  );
};
