import React, { useEffect, useRef, useState } from "react";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { priceFormat } from "utils/format";
import Select from "react-select";
import { Alert, Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { CornerRightDown as CornerRightDownIcon, Rewind } from "react-feather";
import { toast } from "react-toastify";
import {
  ArrowUp,
  Trash,
  ToggleLeft,
  ToggleRight,
  AlertTriangle,
} from "react-feather";

import { ChangeProvider } from "../ChangeProvider";
import { RemoveButton } from "../RemoveButton";
import { MySpinner } from "components/MySpinner";
import { useOrders } from "../OrdersContext";

import {
  changeWarehouse,
  orderFileUpload,
  removeFromReserve,
  removeProducts,
  updateProduct,
} from "services/api";
import { AddProductModal } from "../AddProductModal";
import { MoveToOrderModal } from "../MoveToOrderModal";
import { BarChart } from "components/BarChart";
import { TableButton } from "components/TableButton";

import "./orderProductTable.scss";
import { ChangeWarehouse } from "../ChangeWarehouse";
import { getWarehouses } from "services/apiWarehouse";
import { SkladPopup } from "../SkladPopup";
import classNames from "classnames";
import { Cut } from "components/Cut";
import { existRender } from "utils/existRender";

export const OrderProductTable = ({ order, noEdit }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const { setLoading, loading, setProducts, products } = useOrders();
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openMoveProductModal, setOpenMoveProductModal] = useState(false);
  const inputOrderFile = useRef(null);
  const [orderFile, setOrderFile] = useState(null);
  const [providers, setProviders] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(null);

  const getProviders = () => {
    const providers = order.products?.map((val) => {
      return {
        label: val.provider?.name,
        value: val.provider?.id,
      };
    });
    const output = [];
    if (!providers) return output;
    for (let provider of providers) {
      if (output.find((v) => v.value === provider.value)) continue;
      output.push(provider);
    }

    return output;
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then((res) => setWarehouses(res.filter(v => v.alias !== "return" && v.alias !== "defective")))
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    setProviders(getProviders());
    getAndSetWarehouses();
    // eslint-disable-next-line
  }, [order]);

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const duplicateRender = () => {
    if (products) {
      const duplicates = [];
      for (const product of products) {
        const duplicate = products.filter(
          (prod) => prod.articleSearch === product.articleSearch
        );

        if (duplicate.length > 1) {
          for (const d of duplicate) {
            duplicates.push(d.article);
          }
        }
      }

      const duplicatesUnique = duplicates.filter(unique);

      if (duplicates.length > 1) {
        const duplicatesText = () => duplicatesUnique.join(", ");

        return (
          <Alert color="warning">Дубликаты артикулов {duplicatesText()}</Alert>
        );
      }
    }

    return null;
  };

  const removeProductsHandler = () => {
    if (!window.confirm("Вы хотите удалить товар из заказа?")) return;
    setLoading(true);
    removeProducts({ ids: selected })
      .then((res) => {
        setProducts(res.products);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
        setSelected([]);
      });
  };

  const removeFromReserveHandler = (productId = null) => {
    if (!window.confirm("Вы хотите снять товар с резерва?")) return;
    setLoading(true);
    removeFromReserve({
      ids: productId ? [productId] : selected,
    })
      .then((res) => {
        setProducts(res.products);
        toast.success("Товар успешно снят с резерва");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "filterProviders":
        setSelectedProviders(val);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!products) return;
    setFilteredProducts(() => {
      if (!selectedProviders.length) {
        return products;
      }

      return products.filter((order) =>
        selectedProviders.find((v) => v.value === order.provider?.id)
      );
    });
  }, [products, selectedProviders]);

  useEffect(() => {
    if (!orderFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", orderFile);
    formData.append("id", order.id);
    orderFileUpload(formData)
      .then((res) => {
        setProducts(res.products);
        //setEstimateFromFile(res.items);
      })
      .finally(() => {
        setLoading(false);
        inputOrderFile.current.value = "";
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFile]);

  const productHandler = (oldData, newData, row, column) => {
    if (String(oldData) === String(newData)) return;

    if (column.dataField === "price" && row.price < row.costPrice) {
      toast.warning(`${row.index} - продажа ниже себестоимости!`);
    }

    if (column.dataField === "priceWithoutNds") {
      row.priceWithoutNds = newData.replace(',', '.');
    }

    if (!row.provider?.id) {
      alert("Укажите поставщиков");
      return oldData;
    }

    setLoading(true);
    updateProduct({
      ...row,
      provider: row.provider?.id,
      warehouse: row.warehouse?.id,
    })
      .then((res) => {
        setProducts(res.products);
        // sendTo1SHandler();
      })
      .finally(() => setLoading(false));
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(products.map((val) => val.id));
      }
    },
  };

  const getOrderFile = () => {
    if (inputOrderFile) {
      inputOrderFile.current.click();
    }
  };

  const changeWarehouseHandler = () => {
    setLoading(true);
    setProducts([]);
    changeWarehouse({
      warehouse: warehouse?.value,
      ids: selected,
    })
      .then((res) => {
        setProducts(res.products);
        toast.success("Склад в выбранных товарах успешно изменен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "provider",
      text: "Поставщик",
      formatter: (cell, row) => {
        if (order.carriedOut) {
          return <Cut>{cell?.name}</Cut>;
        }

        const providerStock = row.providersStock.find(
          (val) => val.id === row.provider?.id
        );

        if (
          providerStock ||
          cell?.id === "518680fd-6d10-4543-96a4-108534ba69ca"
        ) {
          return <ChangeProvider row={row} />;
        } else {
          return (
            <div className="provider-block">
              <ChangeProvider row={row} />
              <div className="popup-block">
                <AlertTriangle color="red" className="provider-alert" />
                <div className="provider-popup">
                  {row.providersStock?.map((val) => (
                    <div key={val.id}>{val.name}</div>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <span className="cute">{row.nomenclature?.name}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      formatter: (cell) => `${priceFormat(cell)} р`,
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("editable right bold", { error: cell < row.costPrice }),
      footer: () => null,
      editable: !noEdit,
      hidden: order.priceNds,
    },
    {
      dataField: "priceWithoutNds",
      text: "Цена без НДС",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      formatter: (cell) => `${priceFormat(cell)} р`,
      headerClasses: () => "right",
      classes: () => "editable right bold",
      footer: () => null,
      editable: !noEdit,
      hidden: !order.priceNds,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right bold",
      formatter: (cell, row) => (
        <span className="count-block">
          {row.reserveProduct > 0 && <span className="in-reserve"></span>}
          {cell}
        </span>
      ),
      footer: (columnData) => {
        const total = columnData.reduce(
          (acc, item) => acc + parseFloat(item),
          0
        );
        return total;
      },
      editable: !noEdit,
      validator: (newValue, row) => {
        if (row.reserveProduct > 0) {
          return {
            valid: false,
            message: "Товар в резерве",
          };
        }
        return true;
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell) => `${priceFormat(cell)} р`,
      headerClasses: () => "right",
      footerClasses: () => "right bold",
      classes: () => "right bold",
      editable: false,
      footer: (columnData) => {
        const total = columnData.reduce(
          (acc, item) => acc + parseFloat(item),
          0
        );
        return total && `${priceFormat(total)} р`;
      },
    },
    {
      dataField: "inStock",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <SkladPopup row={row}>
              <BarChart
                color={cell > 0 ? "green" : "red"}
                count={existRender(row)}
              />
            </SkladPopup>
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "reserve",
      text: "Рез.",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell) => <>{cell && <BarChart color="red" count={cell} />}</>,
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "warehouse",
      text: "Склад",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => {
        if (order.carriedOut) {
          return cell?.name;
        }
        return <ChangeWarehouse row={row} warehouses={warehouses} />;
      },
      footer: () => null,
      editable: false,
    },
    {
      dataField: "ordered",
      text: "Зак.",
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      classes: (cell) => "editable",
      footer: () => null,
      formatter: (cell) => (
        <>
          {cell === "Да" ? (
            <ToggleRight color="green" />
          ) : (
            <ToggleLeft color="gray" />
          )}
        </>
      ),
      editor: {
        type: Type.CHECKBOX,
        value: "Да:Нет",
      },
      editable: !noEdit,
    },
    {
      dataField: "dummy_field",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      formatter: (cell, row) => (noEdit ? "" : <RemoveButton id={row.id} />),
      editable: false,
      footer: () => null,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <>
          {row.reserveProduct > 0 && (
            <TableButton
              Icon={Rewind}
              onClick={() => removeFromReserveHandler(row.id)}
            />
          )}
        </>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      footer: () => null,
      editable: false,
    },
  ];

  const rowClasses = (row, rowIndex) => {
    if (!row.carriedOut && order.status?.close) {
      return "error";
    }
  };

  return (
    <>
      <Card className="order-product mb-0">
        <CardBody>
          {loading && <MySpinner overlay />}

          <Row className="order-product__toolbar">
            <Col md="10" className="button-block">
              {!noEdit && (
                <Button
                  color="primary"
                  onClick={() => setOpenModal(true)}
                  className="button-wide"
                >
                  Добавить товар
                </Button>
              )}
              {selected.length > 0 && !noEdit && (
                <>
                  <Button
                    onClick={() => setOpenMoveProductModal(true)}
                    className="button-wide"
                  >
                    <ArrowUp size="15" />
                    Перенести в заказ
                  </Button>
                  <Button onClick={removeProductsHandler} color="danger">
                    <Trash size="15" /> Удалить
                  </Button>
                  <Button
                    onClick={() => removeFromReserveHandler()}
                    color="danger"
                  >
                    <Rewind size="15" /> Снять с резерва
                  </Button>

                  <div style={{ width: "250px" }}>
                    <Select
                      name="warehouse"
                      placeholder="Склад"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={warehouses.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      })}
                      value={warehouse}
                      onChange={setWarehouse}
                    />
                  </div>
                  <Button onClick={changeWarehouseHandler}>
                    Изменить склад
                  </Button>
                </>
              )}

              {!noEdit && (
                <>
                  <Button onClick={getOrderFile} className="button-wide">
                    Импорт
                  </Button>
                  <Input
                    type="file"
                    id="order"
                    hidden
                    innerRef={inputOrderFile}
                    onChange={(e) => setOrderFile(e.target.files[0])}
                  />

                  <a
                    href="https://api.lider-truck.ru/files/templates/orderTemplate.xls"
                    className="btn btn-secondary button-wide"
                  >
                    Скачать шаблон
                    <CornerRightDownIcon size={15} />
                  </a>
                </>
              )}
            </Col>
            <Col md="2">
              <Select
                isMulti
                name="filterProviders"
                placeholder="Фильтр по поставщикам"
                className="react-select-container"
                classNamePrefix="react-select"
                options={providers}
                value={selectedProviders}
                onChange={selectHandler}
              />
            </Col>
          </Row>

          {duplicateRender()}
        </CardBody>
      </Card>

      <BootstrapTable
        wrapperClasses="order-product"
        keyField="id"
        data={filteredProducts}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row, column) =>
            productHandler(oldData, newData, row, column),
        })}
        selectRow={selectRow}
        rowClasses={rowClasses}
      />
      <AddProductModal
        orderId={order.id}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <MoveToOrderModal
        ids={selected}
        orderId={order.id}
        openModal={openMoveProductModal}
        setOpenModal={setOpenMoveProductModal}
      />
    </>
  );
};
