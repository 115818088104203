import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Camera } from "react-feather";
import classNames from "classnames";

import { Cut } from "components/Cut";

import { dateFormat, priceFormat } from "utils/format";
import { updateNomenclature } from "services/api";
import { isCyrillic } from "utils/isCyrillic";
import { ABC } from "pages/nomenclature";

import { ChangeStatus } from "../ChangeStatus";

import "./nomenclatureTable.scss";

export const NomenclatureTable = ({
  nomenclature,
  getAndSetNomenclature,
  setLoading,
  setOpenModal,
  setId,
}) => {
  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      classes: () => "editable",
      formatter: (cell) =>
        isCyrillic(cell) ? (
          <Cut><span className="cyrillic">{cell}</span></Cut>
        ) : (
          <Cut>{cell}</Cut>
        ),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "brandName",
      text: "Бренд",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      formatter: (cell) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      classes: () => "editable",
      formatter: (cell) => <Cut>{cell}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "28%" };
      },
    },
    {
      dataField: "images",
      text: "Фото",
      classes: () => "editable",
      formatter: (cell) => (cell.length > 0 ? <Camera color="gray" /> : ""),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
    },
    {
      dataField: "costPrice",
      text: "Себес-ть",
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell) => "editable right",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      editable: false,
    },
    {
      dataField: "marketplacePrice",
      text: "Площадки",
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("editable right", { "red-bg": cell < row.costPrice }),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "fixPrice",
      text: "Фикс прайс",
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("editable right", { "red-bg": cell < row.costPrice }),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "inStock",
      text: "В нал.",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell) => cell || 0,
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "abc",
      text: "ABC",
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      editable: false,
    },
    {
      dataField: "minStock",
      text: "Мин. ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      editable: false,
    },
    {
      dataField: "status",
      text: "Статус",
      formatter: (cell, row) => <>
        <span>{cell}</span>
        <ChangeStatus
          row={row}
          statuses={ABC.filter(v => v.value === 1 || v.value === 4)}
          setLoading={setLoading}
          getAndSetNomenclature={getAndSetNomenclature}
        />
      </>,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      classes: () => "showChangeStatus",
      editable: false,
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "compatibility",
      text: "Применяемость",
      formatter: (cell) => <Cut>{cell}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      editable: false,
    },
    {
      dataField: "createdAt",
      text: "Дата доб",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      formatter: (cell) => <Cut>{dateFormat(cell)}</Cut>,
      editable: false,
    },
    {
      dataField: "active",
      text: "Активен",
      classes: (cell) =>
        cell === "Нет" ? "editable inactive" : "editable active",
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      },
      editor: {
        type: Type.CHECKBOX,
        value: "Да:Нет",
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
  ];

  const editHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateNomenclature({
      id: row.id,
      name: row.name,
      brand: row.brandId,
      article: row.article,
      fixPrice: row.fixPrice,
      minPrice: row.minPrice,
      costPrice: row.costPrice,
      marketplacePrice: row.marketplacePrice,
      active: row.active === "Да" ? true : false
    }).then((res) => {
      setLoading(true);
      getAndSetNomenclature();
    });
  };

  const rowEvents = {
    onClick: (e, row) => {
      setId(row.id);
      setOpenModal(true);
    },
  };

  return (
    <BootstrapTable
      keyField="id"
      data={nomenclature}
      columns={tableColumns}
      bootstrap4
      bordered={false}
      hover={true}
      rowEvents={rowEvents}
      classes="pointer"
      cellEdit={cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldData, newData, row) =>
          editHandler(oldData, newData, row),
      })}
    />
  );
};
