import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat, priceFormat } from "utils/format";
import { CheckCircle } from "react-feather";

import { Cut } from "components/Cut";

import user from "assets/img/user.svg";

import "./returnsToProviderTable.scss";
import { Status } from "components/Status";
import { StatusOrderToProvider } from "components/StatusOrderToProvider";

export const ReturnsToProviderTable = ({ returnsToProvider, rowEvents, statusHandler, statuses }) => {
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата возврата",
      formatter: (cell) => dateFormat(cell),
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "supplyNumber",
      text: "Пост-е",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "supplyDocNum",
      text: "№ док./дата",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => (
        <Cut>
          {row.supplyFactNum} от {dateFormat(row.supplyDate)}
        </Cut>
      ),
    },
    {
      dataField: "admin",
      text: "Создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <Cut>
          <span className="user-cell">
            <img src={user} alt="user" />
            {cell}
          </span>
        </Cut>
      ),
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "9%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
    {
      dataField: "status",
      text: "Статус",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => cell ? (
        <StatusOrderToProvider
          status={{
            color: cell.color,
          }}
          statuses={statuses}
          statusHandler={(status) => statusHandler(row.id, status)}
        >
          {cell.name}
        </StatusOrderToProvider>
      ) : null,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={returnsToProvider}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer"
        rowEvents={rowEvents}
      />
    </>
  );
};
