import React, { useEffect, useRef, useState } from "react";

import { Head } from "./Head";
import { Footer } from "./Footer";
import { Products } from "./Products";

import "./printUtd.scss";

export const PrintUtd = ({ utd }) => {
  const ref = useRef(null);
  const [page, setPage] = useState(1);
  const [top, setTop] = useState(null);

  useEffect(() => {
    if (ref) {
      setTop(ref.current.getBoundingClientRect().top);
    }
  }, [ref]);

  return (
    <>
      <style>
        {`@media print {
            @page {
              size: A4 landscape;
              margin: 0;
				    }}
          }`
        }
      </style>
      <div className="printUtd" ref={ref}>

        <Head utd={utd} />

        <Products utd={utd} setPage={setPage} page={page} parent={top} />

        <Footer utd={utd} page={page} parent={top} />

      </div>
    </>
  );
};
