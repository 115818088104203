import react, { useEffect, useState } from "react";
import { Button, ButtonGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";

import { Checkbox } from "components/Checkbox";
import { DELIVERY, addressLabel, addressesMap } from "features/Dashboard/OrderForm";
import { CreateAddressModal } from "components/CreateAddressModal";
import ReactInputMask from "react-input-mask";
import { TRANSPORT_COMPANY } from "pages/orderOne";
import { addDeliverySum, getAddresses, updateDateDelivery, updateDelivery, updateDeliveryType, updateOrder, updateOrderAddress } from "services/api";
import { toast } from "react-toastify";
import { dateFormatU } from "utils/format";
import { ROUTES } from "features/Users/Addresses";
import { Check } from "react-feather";

registerLocale("ru", ru);

export const DeliveryBlock = ({
  order,
  setOrder,
  setLoading,
  quantityLabels,
  setQuantityLabels,
  setQuantityPallets,
  quantityPallets
}) => {
  const [deliveryType, setDeliveryType] = useState(null);
  const [address, setAddress] = useState(null);
  const [dateDelivery, setDateDelivery] = useState("");
  const [route, setRoute] = useState("");
  const [deliverySettings, setDeliverySettings] = useState(null);
  const [delivered, setDelivered] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [deliverySum, setDeliverySum] = useState(0);

  const getAndSetAddreses = () => {
    getAddresses(order?.customer?.id)
      .then((res) => setAddresses(addressesMap(res)));
  };

  const setAddressesHandler = (addresses) => {
    setAddresses(addressesMap(addresses));
    setAddress(addressesMap(addresses)[0]);
  };

  useEffect(() => {
    setDeliveryType(order.delivery?.id);
    setDateDelivery(order.dateDelivery ? new Date(order.dateDelivery) : null);
    setDeliverySettings(order.deliverySettings);
    setDelivered(order.delivered);
    setRoute(ROUTES.find(v => v.value === order.route));
    setAddress(order.address ? { label: addressLabel(order.address), value: order.address?.id } : null);
    setDeliverySum(order?.deliverySum || "");

    getAndSetAddreses();

  }, [order]);

  const deliveryTypeHandler = (val) => {
    setLoading(true);
    setDeliveryType(val.value);
    updateDeliveryType({
      id: order?.id,
      deliveryType: val.value
    })
      .then((res) => {
        setOrder(res);
        toast.success("Тип доставки успешно сохранен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  }

  const deliveryAddressHandler = (val) => {
    setLoading(true);
    setAddress(val);
    setDeliverySettings(val);
    updateOrderAddress({
      id: order.id,
      address: val?.value
    })
      .then((res) => {
        setOrder(res);
        toast.success("Способ доставки успешно сохранен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const deliveredHandler = () => {
    setLoading(true);
    updateOrder({
      id: order.id,
      delivered: !delivered,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Статус доставки успешно обновлен");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const updateDeliveryInfoHandler = () => {
    setLoading(true);
    updateOrder({
      id: order.id,
      deliverySettings,
      quantityLabels,
      quantityPallets,
      route: route?.value
    })
      .then((res) => {
        setOrder(res);
        toast.success("Данные доставки успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message)
      })
      .finally(() => setLoading(false));
  };

  const updateDateHandler = (val) => {
    setLoading(true);
    setDateDelivery(val);
    updateDateDelivery({
      id: order.id,
      dateDelivery: dateFormatU(val),
    })
      .then((res) => {
        setOrder(res);
        toast.success("Дата доставки успешно сохранена");
      })
      .catch((e) => {
        if (e.response?.data?.type === "deliveryDateError") {
          setDateDelivery(null);
        }
        toast.error(e.response?.data?.message)
      })
      .finally(() => setLoading(false));
  };

  const setDeliverySumHandler = () => {
    if (!dateDelivery) {
      toast.error("Укажите дату доставки/самовывоза");
      return;
    }
    setLoading(true);
    addDeliverySum({
      id: order.id,
      deliverySum,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Сумма доставки успешно сохранена");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!quantityPallets || !quantityLabels) return;
    if (quantityPallets > quantityLabels) {
      toast.error("Кол-во паллет не может быть больше кол-ва мест");
      setQuantityPallets(quantityLabels);
    }
  }, [quantityLabels, quantityPallets]);

  const payers = () => [
    { label: "ЛИДЕР ТРАК", value: true },
    {
      label: order.customer?.name,
      value: false,
    },
  ];

  return <>
    <Row className="elem">
      <div className="elem-field">
        <Label>Способ доставки</Label>
        <Select
          name="delivery"
          placeholder="Способ доставки"
          className="react-select-container"
          classNamePrefix="react-select"
          options={DELIVERY}
          value={DELIVERY.find((val) => deliveryType === val.value)}
          onChange={(val) => deliveryTypeHandler(val)}
        />
      </div>
      {deliveryType === 1 && (
        <div className="elem-field">
          <Label>Дата самовывоза</Label>
          <DatePicker
            placeholderText="__.__.____"
            selected={dateDelivery}
            onChange={updateDateHandler}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            className="form-control date"
            customInput={<ReactInputMask mask={"99.99.9999"} />}
          />
        </div>
      )}
      {deliveryType !== 1 && (
        <>
          <div className="elem-field delivery-settings">
            <Label>Адрес доставки</Label>

            <div className="d-flex justify-content-between select_address">
              <Select
                placeholder="Выберите адрес доставки"
                className="react-select-container"
                classNamePrefix="react-select"
                options={addresses}
                onChange={deliveryAddressHandler}
                value={address}
              />
              <CreateAddressModal
                customer={order?.customer}
                setAddresses={(res) => setAddressesHandler(res)}
              />
            </div>
          </div>
          <div className="elem-field">
            <Label>Дата доставки</Label>
            <DatePicker
              placeholderText="__.__.____"
              selected={dateDelivery}
              onChange={updateDateHandler}
              dateFormat="dd.MM.yyyy"
              locale="ru"
              className="form-control date"
              customInput={<ReactInputMask mask={"99.99.9999"} />}
            />
          </div>
        </>
      )}
      <div className="elem-field deliverySum">
        <Label>Сумма доставки</Label>
        <ButtonGroup>
          <Input
            name="deliverySum"
            placeholder="Сумма доставки"
            onChange={e => setDeliverySum(e.target.value)}
            value={deliverySum}
          />
          <Button onClick={setDeliverySumHandler}>
            <Check size={15} />
          </Button>
        </ButtonGroup>
      </div>
    </Row>

    <Row className="elem">
      <div className="address">
        {(deliveryType === 2 || deliveryType === 3) && (
          <>
            <div>
              <span>Город:</span>
              <span>
                <Input
                  name="city"
                  placeholder="Город"
                  onChange={(e) =>
                    setDeliverySettings({
                      ...deliverySettings,
                      city: e.target.value,
                    })
                  }
                  readOnly
                  value={order?.address?.city || ""}
                />
              </span>
            </div>
            <div>
              <span>Адрес доставки:</span>
              <span>
                <Input
                  name="address"
                  placeholder="Адрес доставки"
                  onChange={(e) =>
                    setDeliverySettings({
                      ...deliverySettings,
                      address: e.target.value,
                    })
                  }
                  readOnly
                  value={order?.address?.address || ""}
                />
              </span>
            </div>
          </>
        )}
        <>
          {deliveryType === 3 && (
            <>
              <div>
                <span>Транспортная компания:</span>
                <span>
                  <Select
                    name="transportCompany"
                    placeholder="Транспортная компания"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={TRANSPORT_COMPANY}
                    value={TRANSPORT_COMPANY.find(
                      (item) =>
                        item.value === order?.address?.transportCompany
                    )}
                    onChange={(val) => {
                      setDeliverySettings({
                        ...deliverySettings,
                        transportCompany: val.value,
                      });
                    }}
                    isDisabled
                  />
                </span>
              </div>
              <div>
                <span>Плательщик:</span>
                <span>
                  <Select
                    name="senderIsPayer"
                    placeholder="Плательщик"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={payers()}
                    value={payers().find(
                      (item) => item.value === deliverySettings?.senderIsPayer
                    )}
                    onChange={(val) => {
                      setDeliverySettings({
                        ...deliverySettings,
                        senderIsPayer: val.value,
                      });
                    }}
                  />
                </span>
              </div>
            </>
          )}
        </>
        {(deliveryType === 2 || deliveryType === 3) && (
          <div>
            <span>Контакты получателя:</span>
            <span>
              <Input
                name="contact"
                placeholder="Контакты получателя"
                onChange={(e) =>
                  setDeliverySettings({
                    ...deliverySettings,
                    contact: e.target.value,
                  })
                }
                value={deliverySettings?.contact || ""}
              />
            </span>
          </div>
        )}

        <div>
          <span>Комментарий к отгрузке:</span>{" "}
          <span>
            <Input
              name="comment"
              placeholder="Комментарий к отгрузке"
              onChange={(e) =>
                setDeliverySettings({
                  ...deliverySettings,
                  comment: e.target.value,
                })
              }
              value={deliverySettings?.comment || ""}
            />
          </span>
        </div>
        {(deliveryType === 2 || deliveryType === 3) && (
          <>
            <div>
              <span>Отправить на сборку до:</span>{" "}
              <span>
                {ROUTES.find(v => v.value === route?.value)?.label}
              </span>
            </div>
            <div>
              <span>Общее кол-во мест:</span>{" "}
              <span className="mr-3">
                <Input
                  name="quantityLabels"
                  placeholder="мест"
                  onChange={(e) => setQuantityLabels(e.target.value)}
                  value={quantityLabels}
                  className="quantityLabels"
                />
              </span>
              <span className="mr-3">Паллет:</span>{" "}
              <span>
                <Input
                  name="quantityPallets"
                  placeholder="паллет"
                  onChange={(e) => setQuantityPallets(e.target.value)}
                  value={quantityPallets}
                  className="quantityLabels"
                />
              </span>
            </div>
          </>
        )}
      </div>
    </Row>

    <Row className="elem">
      <Button
        onClick={updateDeliveryInfoHandler}
        color="primary"
        className="mt-1 mr-3"
      >
        Сохранить данные доставки
      </Button>

      <div className="elem-field">
        <Label>Отгружено</Label>
        <Checkbox
          name="delivered"
          value="delivered"
          checked={delivered}
          onChange={(e) => {
            deliveredHandler(e.target.checked);
          }}
          disabled={(order.status?.sort !== 5 && order.status?.sort !== 6)}
        />
      </div>
    </Row>
  </>
}